import React from "react";
import "./Contact.css";
import purplebox from "../Images/box_purple.jpg";

export class Contact extends React.Component {
  render() {
    return (
      <div className="contact-container">
        <div className="contact-container-image">
          <img src={purplebox} alt="artwork" />
        </div>
        <div>
        <p>
          Email:{"  "}
          <a
            href="mailto:joey.steigelman@gmail.com?subject=Inquiry"
            className="contact-mobile-email"
          >
            joey.steigelman@gmail.com
          </a>
        </p>
        </div>

      </div>
    );
  }
}
